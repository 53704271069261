<template>
  <main class="bg-primary-transparent xl:px-6 lg:px-6 md:px-8 px-3 pb-16">
    <section class="w-full">
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <h1 class="text-xl font-bold capitalize flex items-center mb-6">
        <arrow-left-icon
          size="1.2x"
          class="custom-class mr-2 text-primary-color cursor-pointer"
          @click="$router.go(-1)"
        ></arrow-left-icon>
        <span>Direct Payment</span>
      </h1>
      <section class="w-full flex items-center">
        <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
          <dashboard-card
            class="bg-primary-color mr-6"
            @openModal="fundingWallet = true"
            @toggleBalance="toggleWalletBalance"
            :show-balance="showWalletBalance"
            :data-set="'balance'"
          >
            <img
              src="@/assets/images/subscription-icon-light.svg"
              alt="go to subscriptions"
              slot="icon"
              class="w-8"
            />
            <span slot="title">Wallet Balance</span>
            <span slot="action" class="text-primary-color">Top up</span>
            <span slot="amount" class="inline-block w-14">
              <span v-if="showWalletBalance" class="w-full"
                >₦{{ walletBalance }}</span
              >
              <img
                v-else
                src="@/assets/images/encrypt.svg"
                alt="wallet balance"
                class="w-full"
              />
            </span>
          </dashboard-card>
          <dashboard-card
            class="bg-primary-green dark:bg-dark-mode-green"
            @toggleBalance="toggleRewardPoints"
            :show-balance="showRewardPoints"
            :data-set="'rewards'"
          >
            <img
              src="@/assets/images/reward-pack.svg"
              alt="go to subscriptions"
              slot="icon"
            />
            <span slot="title">Total Reward points</span>
            <span
              slot="action"
              class="text-primary-green dark:text-dark-mode-green"
              >Redeem</span
            >
            <span slot="amount" class="inline-block w-14">
              <span v-if="showRewardPoints" class="full">{{
                rewardPoints
              }}</span>
              <img
                v-if="!showRewardPoints"
                src="@/assets/images/encrypt.svg"
                alt="reward points"
                class="w-full"
              />
            </span>
          </dashboard-card>
        </div>
      </section>
    </section>
    <section class="my-12 relative">
      <h1 class="font-bold text-lg mb-4">All Merchants</h1>
      <input
        v-model="searchTerm"
        v-debounce:1000ms="filterMerchants"
        type="search"
        placeholder="Search merchants..."
        class="
          block
          w-72
          p-2
          pl-10
          rounded-md
          border border-border-color
          bg-transparent
          focus:outline-none
        "
      />
      <search-icon
        size="1.3x"
        class="custom-class absolute bottom-2.5 left-2"
      ></search-icon>
    </section>
    <LoadingSpinner v-if="loading && !merchants" />
    <p
      v-if="!loading && merchants && !merchants.length"
      class="text-center font-medium text-lg my-12"
    >
      Merchants coming soon!!
    </p>
    <ul
      v-if="!loading && merchants && merchants.length"
      class="
        xl:w-4/5
        lg:w-full
        md:w-full
        w-full
        grid
        xl:grid-cols-4
        lg:grid-cols-3
        md:grid-cols-3
        grid-cols-2
        xl:gap-6
        lg:gap-6
        md:gap-6
        gap-5
        mt-12
      "
    >
      <li
        v-for="(merchant, index) in merchants"
        :key="index"
        class="
          p-3.5
          bg-white
          dark:bg-dark-mode-primary
          rounded
          pb-6
          flex flex-col
          justify-between
        "
      >
        <div class="w-20 h-20 rounded-full bg-gray-200">
          <img
            v-if="merchant.image"
            :src="`${url}/${merchant.image}`"
            :alt="merchant.name"
            class="w-full h-full object-cover rounded-full"
          />
        </div>
        <h1 class="font-bold mt-2 mb-3">{{ merchant.name }}</h1>
        <p class="text-sm">{{ merchant.location }}</p>
        <button
          class="
            bg-primary-color
            text-white text-xs
            rounded-md
            block
            w-28
            py-2
            mt-6
            ml-auto
          "
          @click.prevent="openDirectPaymentModal(merchant)"
        >
          Direct Payment
        </button>
      </li>
    </ul>
    <custom-modal v-if="fundingWallet">
      <FundWallet slot="cart" @closeModal="fundingWallet = false" />
    </custom-modal>
    <custom-modal v-if="makingDirectPayment">
      <DirectPayment
        slot="cart"
        :staffList="staffList"
        @closeModal="makingDirectPayment = false"
        @make-payment="makeDirectPayment"
      />
    </custom-modal>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import DirectPayment from '@/components/DirectPayment.vue'
import FundWallet from '@/components/FundWallet.vue'
import { SearchIcon, ArrowLeftIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'Subscriptions',
  components: {
    DashboardCard,
    SearchIcon,
    ArrowLeftIcon,
    DirectPayment,
    FundWallet,
  },
  mounted() {
    this.fetchMerchantByCategory()
  },
  data() {
    return {
      merchants: null,
      staffList: null,
      searchTerm: '',
      showWalletBalance: false,
      showRewardPoints: false,
      notification: null,
      makingDirectPayment: false,
      fundingWallet: false,
      loading: false,
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints', 'currentMerchant']),
  },
  methods: {
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    openDirectPaymentModal(merchant) {
      this.$store.commit('SET_CURRENT_MERCHANT', merchant)
      this.makingDirectPayment = true
      this.getStaffList(merchant.id)
    },
    async makeDirectPayment(payload) {
      try {
        const { status } = await this.axios.post(
          `/account/products/branches/${this.currentMerchant.id}/direct-pay`,
          payload
        )
        if (status === 200) {
          this.makingDirectPayment = false
          this.notification = {
            type: 'success',
            message: 'Payment successful',
          }
        }
      } catch (error) {
        this.makingDirectPayment = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async getStaffList(branchId) {
      try {
        const { status, data } = await this.axios.get(
          `account/products/branches/${branchId}/staff`
        )

        if (status === 200) {
          this.staffList = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
    async fetchMerchantByCategory() {
      try {
        this.loading = true

        const { status, data } = await this.axios.get(
          'account/products/branches'
        )

        if (status === 200) {
          this.merchants = data.data.branches
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
    async filterMerchants() {
      try {
        this.loading = true

        const { status, data } = await this.axios.get(
          `account/products/branches?search=${this.searchTerm}`
        )

        if (status === 200) {
          this.merchants = data.data.branches
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
  },
}
</script>
