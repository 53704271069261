<template>
  <main class="bg-primary-transparent xl:px-6 lg:px-6 md:px-8 px-3 pb-16">
    <section class="w-full">
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <main>
        <h1 class="text-xl font-bold capitalize flex items-center mb-6">
          <arrow-left-icon
            size="1.2x"
            class="custom-class mr-2 text-primary-color cursor-pointer"
            @click="$router.go(-1)"
          ></arrow-left-icon>
          <span>Utilities</span>
        </h1>
        <section class="w-full flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @openModal="fundingWallet = true"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
      </main>
      <section class="my-12">
        <main
          class="
            mt-6
            grid
            xl:grid-cols-6
            lg:grid-cols-4
            md:grid-cols-3
            grid-cols-2
            gap-4
          "
        >
          <quick-actions-card
            :card-data="{
              background: 'rgba(172, 93, 93, 0.1)',
              src: 'buy-data.svg',
              alt: 'Buy Data',
              heading: 'Buy Data',
              headingColor: '#AC5D5D',
              content: ' Easily top up your mobile data.',
            }"
            @openModal="buyingData = true"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(77, 115, 191, 0.1)',
              src: 'mobile-phone.svg',
              alt: 'Buy Airtime',
              heading: 'Buy Airtime',
              headingColor: '#4267B2',
              content: 'Buy Airtime from your favourite network',
            }"
            @openModal="buyingAirtime = true"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(131, 181, 96, 0.1)',
              src: 'tv.svg',
              alt: 'Tv Subscription',
              heading: 'TV Subscription',
              headingColor: '#83B560',
              content: 'Buy TV cards and Subscribe for services.',
            }"
            @openModal="buyingTvSubscription = true"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(161, 207, 129, 0.25)',
              src: 'electricity.svg',
              alt: 'Buy Electricity',
              heading: 'Buy Electricity',
              headingColor: '#779464',
              content: 'Easily buy electricity prepaid card.',
            }"
            @openModal="buyingElectricity = true"
          />
        </main>
      </section>
    </section>
    <section class="mt-12 relative">
      <h1 class="font-bold text-lg mb-4">Utility Transactions</h1>
      <input
        type="search"
        class="
          block
          w-80
          p-2
          pl-10
          rounded-md
          border border-border-color
          bg-transparent
          focus:outline-none
        "
      />
      <search-icon
        size="1.3x"
        class="custom-class absolute bottom-2.5 left-2"
      ></search-icon>
    </section>
    <DataTable :transactions="recentTransactions" />
    <custom-modal v-if="buyingAirtime">
      <BuyAirtime
        slot="cart"
        @closeModal="buyingAirtime = false"
        @utility-purchase="displayNotification"
      />
    </custom-modal>
    <custom-modal v-if="buyingData">
      <BuyData
        slot="cart"
        @closeModal="buyingData = false"
        @utility-purchase="displayNotification"
      />
    </custom-modal>
    <custom-modal v-if="buyingTvSubscription">
      <BuyTvSubscription
        slot="cart"
        @closeModal="buyingTvSubscription = false"
        @tv-sub="tvSub"
      />
    </custom-modal>
    <custom-modal v-if="buyingElectricity">
      <BuyElectricity slot="cart" @closeModal="buyingElectricity = false" />
    </custom-modal>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import QuickActionsCard from '@/components/QuickActionsCard.vue'
import { SearchIcon, ArrowLeftIcon } from 'vue-feather-icons'
import BuyAirtime from '@/components/BuyAirtime.vue'
import BuyData from '@/components/BuyData.vue'
import BuyTvSubscription from '@/components/BuyTvSubscription.vue'
import BuyElectricity from '@/components/BuyElectricity.vue'
import DataTable from '@/components/DataTable.vue'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'Subscriptions',
  components: {
    DashboardCard,
    QuickActionsCard,
    SearchIcon,
    ArrowLeftIcon,
    BuyAirtime,
    BuyData,
    BuyTvSubscription,
    BuyElectricity,
    DataTable,
  },
  mounted() {
    this.stripeTable()
    this.fetchRecentTransactions()
  },
  watch: {
    mode() {
      this.stripeTable()
    },
  },
  data() {
    return {
      showWalletBalance: false,
      showRewardPoints: false,
      notification: null,
      buyingAirtime: false,
      buyingElectricity: false,
      buyingData: false,
      buyingTvSubscription: false,
      recentTransactions: null,
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints', 'mode']),
  },
  methods: {
    displayNotification(payload) {
      this.notification = {
        type: payload.type,
        message: payload.message,
      }

      this.buyingAirtime = false
      this.buyingElectricity = false
      this.buyingData = false

      this.fetchWalletBalance()
    },
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    stripeTable() {
      const nodes = document.querySelectorAll('.data-items')
      nodes.forEach((node, index) => {
        if (this.mode !== 'dark' && index % 2 === 0) {
          node.classList.remove('bg-legend-black')
          node.classList.add('bg-gray-100')
        } else if (this.mode === 'dark' && index % 2 === 0) {
          node.classList.remove('bg-gray-100')
          node.classList.add('bg-legend-black')
        }
      })
    },
    async fetchWalletBalance() {
      try {
        const { status, data } = await this.axios.get('/account/wallet')
        if (status === 200) {
          this.$store.commit('SET_WALLET_BALANCE', data.data.balance)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    tvSub(payload) {
      this.buyingTvSubscription = false
      this.notification = {
        type: payload.type,
        message: payload.message,
      }

      this.fetchWalletBalance()
    },
    async fetchRecentTransactions() {
      try {
        const { status, data } = await this.axios.get(
          '/account/transactions?filter=utilities'
        )
        if (status === 200) {
          this.recentTransactions = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
